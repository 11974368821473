import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import useStyles from '../style'
import { useTranslation } from 'react-i18next'
import LinearProgress from '@material-ui/core/LinearProgress'
import { TextField } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { WEB_VERSION } from '../../../constants/global_constants'
import { alertActions } from '../../../redux/actions/alert_actions'
import { userActions } from '../../../redux/actions/user_actions'

const AppUrl = process.env.PUBLIC_URL

const MultifactorCode = ({
  totp,
  alertReducer,
  clear,
  authenticationReducer,
  history,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [code, setCode] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    clear()
  }

  const dispatch = useDispatch()

  const { email, password } = history.location.state

  const handleSubmit = (e) => {
    e.preventDefault()
    totp(email, password, code).catch((error) => {
      if (error) {
        dispatch(alertActions.error(t('login.index.otpInvalid')))
      }
    })
  }

  const lastAlert =
    alertReducer.messages.length > 0
      ? alertReducer.messages[alertReducer.messages.length - 1]
      : null
  if (lastAlert) clear()

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        component={Paper}
        elevation={6}
        square
        className={classes.gridContainerStyle}
      >
        <div className={classes.paper}>
          <img
            alt={t('login.index.altImgLogo')}
            src={AppUrl + '/content/img/logo.png'}
            className={classes.logo}
          />
          <p className={classes.titleLogin}>{t('login.index.LoginTitle')}</p>
          {/* <Typography component="h1" variant="h5">
                        Iniciar sesión
                    </Typography> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="code"
            label={t('login.index.authenticationCode')}
            type="text"
            id="code"
            autoComplete="current-code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <form className={classes.form} onSubmit={handleSubmit}>
            {authenticationReducer.loginPending && <LinearProgress />}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('login.index.login')}
            </Button>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.silence.eco">
                  {t('login.index.CopyrightText')}
                </Link>{' '}
                {new Date().getFullYear()}
                {'. - v' + WEB_VERSION}
              </Typography>
            </Box>
          </form>
          {lastAlert &&
            enqueueSnackbar(lastAlert.message, {
              variant: lastAlert.type,
              autoHideDuration: 3000,
              onClose: handleCloseAlert,
            })}
        </div>
      </Grid>
    </Grid>
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer } = state
  return { alertReducer, authenticationReducer }
}

const actionCreators = {
  totp: userActions.totp,
  clear: alertActions.clear,
}

export default connect(mapState, actionCreators)(MultifactorCode)
