import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { connect } from 'react-redux'
import useStyles from '../style'
import { useTranslation } from 'react-i18next'
import { WEB_VERSION } from '../../../constants/global_constants'

const AppUrl = process.env.PUBLIC_URL

const MultifactorQR = ({ history }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const { email, password, qrBase64 } = history.location.state

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        component={Paper}
        elevation={6}
        square
        className={classes.gridContainerStyle}
      >
        <div className={classes.paper}>
          <img
            alt={t('login.index.altImgLogo')}
            src={AppUrl + '/content/img/logo.png'}
            className={classes.logo}
          />
          <p className={classes.titleLogin}>{t('login.index.LoginTitle')}</p>
          {/* <Typography component="h1" variant="h5">
                        Iniciar sesión
                    </Typography> */}

          <form className={classes.form} onSubmit={handleSubmit}>
            <p style={{ textAlign: 'center' }}>
              Scan this QR with Microsoft Authenticator
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 32,
                marginBottom: 16,
              }}
            >
              <img
                src={`data:image/png;base64,${qrBase64}`}
                width={'100%'}
                alt="QR"
                style={{
                  maxWidth: 320,
                }}
              />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() =>
                history.push({
                  pathname: '/multifactor-code',
                  state: { email, password },
                })
              }
            >
              {t('login.index.continue')}
            </Button>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.silence.eco">
                  {t('login.index.CopyrightText')}
                </Link>{' '}
                {new Date().getFullYear()}
                {'. - v' + WEB_VERSION}
              </Typography>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

function mapState(state) {
  const { alertReducer, authenticationReducer } = state
  return { alertReducer, authenticationReducer }
}

export default connect(mapState)(MultifactorQR)
