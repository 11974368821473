import i18next from 'i18next'

import { validCIF, validDNI, validNIE } from 'spain-id'

export const validations = {
  required,
  alMostOne,
  compareDate,
  checkExtensionFile,
  validateArray,
  checkEqualsLength,
  validateEmail,
  validateName,
  validateLastName,
  validateMaxLength,
  validateMinLength,
  isEmptyObject,
  minValue,
  maxValue,
  validatePhone,
  validateDNI,
  validateCIF_Nif,
  validateID,
  validateLongitude,
  validateLatitude,
  validateFloat,
  validateRow,
  validateHigher,
  validateHigherInt,
}

let NAME_REGEX =
  /^[a-zA-ZÀ-ÿ\-'`\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
let LASTNAME_REGEX =
  /^[a-zA-ZÀ-ÿ\-'`\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ-\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/
let EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
let PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/
let PASSPORT_REGEX = /^[a-z]{3}[0-9]{6}[a-z]?$/i
let DNI_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
let NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i
let PHONE_REGEX =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
let LATITUDE_REGEX =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
let LONGITUDE_REGEX =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/

function validateEmail(email) {
  const regex = EMAIL_REGEX
  let result = regex.test(String(email.trim()).toLowerCase())
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.email') }
}

function validateName(name) {
  const regex = NAME_REGEX
  let result = regex.test(String(name.trim()))
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.name') }
}

function validateLastName(lastName) {
  const regex = LASTNAME_REGEX
  let result = regex.test(String(lastName.trim()))
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.lastName') }
}

function validateMinLength(field, min) {
  let result = field.trim().length >= min
  return result
    ? { result, message: '' }
    : {
        result,
        message: i18next.t('validations.register.minLength') + min.toString(),
      }
}

function validateMaxLength(field, max) {
  let result = field.trim().length <= max
  return result
    ? { result, message: '' }
    : {
        result,
        message: i18next.t('validations.register.maxLength') + max.toString(),
      }
}

function minValue(field, min) {
  let result = parseInt(field.toString().trim()) >= min
  return result
    ? { result, message: '' }
    : {
        result,
        message: i18next.t('validations.register.minValue') + min.toString(),
      }
}

function maxValue(field, max) {
  let result = parseInt(field.toString().trim()) <= max
  return result
    ? { result, message: '' }
    : {
        result,
        message: i18next.t('validations.register.maxValue') + max.toString(),
      }
}

function validatePhone(phone) {
  const regex = PHONE_REGEX
  let result = regex.test(String(phone.trim()))
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.phone') }
}

function validateDNI(dni, isNIE) {
  let numero = dni.substr(0, dni.length - 1)
  let letr = dni.substr(dni.length - 1, 1)
  numero = numero % 23
  let letra = 'TRWAGMYFPDXBNJZSQVHLCKET'
  letra = letra.substring(numero, numero + 1)
  if (letra !== letr.toUpperCase()) {
    return {
      result: false,
      message: i18next.t('validations.register.document'),
    }
  } else {
    return { result: true, message: '' }
  }
}

function validatePassport(passport) {
  return { result: true, message: '' }
}

function validateNIE(nie) {
  // Change the initial letter for the corresponding number and validate as DNI
  var nie_prefix = nie.charAt(0)
  switch (nie_prefix) {
    case 'X':
      nie_prefix = 0
      break
    case 'Y':
      nie_prefix = 1
      break
    case 'Z':
      nie_prefix = 2
      break
  }
  let result = validateDNI(nie_prefix + nie.substr(1), true)
  return result
}

function validateID(id) {
  let type = spainIDType(id?.trim() ?? '')
  switch (type) {
    case 'DNI':
      return validateDNI(id.trim())
    case 'PASSPORT':
      return validatePassport(id.trim())
    case 'NIE':
      return validateNIE(id.trim())
    default:
      return {
        result: false,
        message: i18next.t('validations.register.document'),
      }
  }
}

function validateLatitude(lat) {
  const regex = LATITUDE_REGEX
  const result = regex.test(lat)
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.lat') }
}

function validateLongitude(lng) {
  const regex = LONGITUDE_REGEX
  const result = regex.test(lng)
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.lng') }
}

function validateFloat(price) {
  let result = !isNaN(price)
  if (!price) result = false
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.number') }
}

function validateHigher(from = 0, value = null) {
  let result = !value || value >= 0
  return result
    ? { result: true, message: '' }
    : { result: false, message: 'Numero invalido' }
}

function validateHigherInt(from = 0, value = null) {
  if (!value || String(value).includes('.') || String(value).includes(','))
    return { result: false, message: i18next.t('validations.validInt') }
  let result = value >= from || !value
  return result
    ? { result: true, message: '' }
    : { result: false, message: i18next.t('validations.validInt') }
}

function spainIDType(str) {
  if (str.match(DNI_REGEX)) {
    return 'DNI'
  }
  if (str.match(PASSPORT_REGEX)) {
    //pendent
    return 'PASSPORT'
  }
  if (str.match(NIE_REGEX)) {
    return 'NIE'
  }
  return 'INVALID'
}

function validateCIF_Nif(value) {
  let result =
    validCIF(value.trim()) || validDNI(value.trim()) || validNIE(value.trim())
  return result
    ? { result: true, message: '' }
    : { result: false, message: i18next.t('validations.register.document') }
}

function required(value, callback = null, diferentValue = null) {
  if ((value !== null && value.length === 0) || value == diferentValue) {
    return { result: false, message: i18next.t('validations.required') }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function validateArray(array) {
  const result = array.length > 0
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.required') }
}

function checkEqualsLength(value, length) {
  const result = value == length
  return result
    ? { result, message: '' }
    : {
        result,
        message: i18next.t('batteries.pickUpRequest.validations.sameQuantity'),
      }
}

function checkExtensionFile(fileName, extension, callback = null) {
  var ext = fileName.split('.')
  if (extension.toLowerCase() !== ext[ext.length - 1].toLowerCase()) {
    return {
      result: false,
      message: i18next.t('validations.checkExtensionFile'),
    }
  } else {
    if (callback) {
      return callback
    }
    return { result: true, message: '' }
  }
}

function compareDate(date1, date2) {
  var d1 = new Date(date1)
  var d2 = new Date(date2)
  if (d2.getTime() < d1.getTime()) {
    return { result: false, message: i18next.t('validations.comparateDate') }
  } else {
    return { result: true, message: '' }
  }
}

function alMostOne(array) {
  var result = false
  var message = i18next.t('validations.alMostOne')
  array = array.filter(function (el) {
    return el !== ''
  })
  if (array.length === 0) {
    return { result, message }
  } else {
    return { result: true, message: '' }
  }
}

function validateRow(letter) {
  const result = letter.length === 1 && letter.match(/[a-z]/i)
  return result
    ? { result, message: '' }
    : { result, message: i18next.t('validations.register.validateRow') }
}

function isEmptyObject(obj) {
  let result = false
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) result = true
  }
  return {
    result,
    message: result ? '' : i18next.t('validations.register.isEmptyObject'),
  }
}
