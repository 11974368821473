import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import accountUser from './components/accountUser'
import Batteries from './components/batteries'
import PickUpRequest from './components/batteries/pickUpRequest'
import ReturnBatteries from './components/batteries/returnBatteries'
import Billings from './components/billing'
import Customers from './components/customers'
import Discounts from './components/discounts'
import Help from './components/help'
import Home from './components/home'
import Incidents from './components/incidents'
import Insurances from './components/insurances'
import Login from './components/login'
import MultifactorCode from './components/login/multifactorCode'
import MultifactorQr from './components/login/multifactorQr'
import Map from './components/map'
import MyData from './components/myData'
import Operator from './components/operator'
import OperatorUser from './components/operatorUser'
import Product from './components/products'
import Rates from './components/rates'
import Report from './components/reports'
import Stations from './components/stations'
import Subscriptions from './components/subscriptions'
import SubscriptionConfirmation from './components/subscriptions/confirmation'
import Supervisors from './components/supervisors'
import SwapsManual from './components/swap_manual'
import Swaps from './components/swaps'
import Technicians from './components/technicians'
import Zones from './components/zones'
import { history } from './helpers/history'
import { userActions } from './redux/actions/user_actions'

const Root = ({ authenticationReducer, checkToken }) => {
  const { auth } = authenticationReducer

  useEffect(() => {
    if (
      ![
        '/subscriptions/confirmation',
        '/multifactor',
        '/multifactor-code',
      ].includes(window.location.pathname)
    ) {
      checkToken()
    }
  }, [auth, checkToken])

  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/subscriptions/confirmation"
          component={SubscriptionConfirmation}
        ></Route>
        <PrivateRoute
          path="/home"
          component={Home}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <Route path="/login" component={Login}></Route>
        <Route path="/multifactor" component={MultifactorQr}></Route>
        <Route path="/multifactor-code" component={MultifactorCode}></Route>
        <PrivateRoute
          path="/customers"
          component={Customers}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/zones"
          component={Zones}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/subscriptions"
          component={Subscriptions}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/returnBatteries"
          component={ReturnBatteries}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/technicians"
          component={Technicians}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operators"
          component={Operator}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/stations"
          component={Stations}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/batteries"
          component={Batteries}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/requestBattery"
          component={PickUpRequest}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/map"
          component={Map}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/report"
          component={Report}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/incidents"
          component={Incidents}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/myData"
          component={MyData}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/payments"
          component={Billings}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/supervisors"
          component={Supervisors}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/rates"
          component={Rates}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/swaps"
          component={Swaps}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/insurances"
          component={Insurances}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/operatorUser"
          component={OperatorUser}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/accountUser"
          component={accountUser}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/discounts"
          component={Discounts}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/help"
          component={Help}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/products"
          component={Product}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <PrivateRoute
          path="/swapsManual"
          component={SwapsManual}
          history={history}
          auth={auth}
        ></PrivateRoute>
        <Route path="/" exact render={() => <Redirect to="/home" />} />
        <Route path="/" render={() => <Redirect to="/help" />} />
      </Switch>
    </Router>
  )
}

function mapState(state) {
  const { authenticationReducer } = state
  return { authenticationReducer }
}

const actionCreators = {
  checkToken: userActions.checkToken,
}

export default connect(mapState, actionCreators)(Root)
