import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import DISABLED_CONSTANTS from '../../../helpers/modalConstants'
import { setupUserState } from '../../../helpers/user'
import { validations } from '../../../helpers/validation'
import { customerActions } from '../../../redux/actions/customers_actions'
import ModalConfirm from '../../shared/modalconfirm'
import DocumentsCarousel from './carousel'
import useStyles from './styles'

const CustomerDocumentsValidation = ({
  open,
  customerData,
  customerId,
  getCustomer,
  cannotOperate,
  onClose,
  customerReducer,
  canOperate,
  update,
  from = 'Customers',
}) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const [userData, setUserData] = useState({})
  const [oldUserData, setOldUserData] = useState({})

  const [isRefreshDataModalOpened, setIsRefreshDataModalOpened] =
    useState(false)
  const [invalidateModalOpened, setInvalidateModalOpened] = useState(false)
  const [validateModalOpened, setValidateModalOpened] = useState(false)
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] =
    useState(false)
  const [imageUrls, setImageUrls] = useState([])

  const [rotateDegDocuments, setRotateDegDocuments] = useState({
    selected: '',
    dniFront: 270,
    dniBack: 270,
  })

  const [errors, setErrors] = useState({
    nif: { result: false, message: '' },
    firstName: { result: false, message: '' },
    lastName: { result: false, message: '' },
    phone: { result: false, message: '' },
    birthday: { result: false, message: '' },
    gender: { result: false, message: '' },
    country: { result: false, message: '' },
    city: { result: false, message: '' },
    address: { result: false, message: '' },
    postalCode: { result: false, message: '' },
    docType: { result: false, message: '' },
  })

  useEffect(() => {
    if (customerId) getCustomer(customerId)
  }, [])

  useEffect(() => {
    setUserData(setupUserState(customerReducer))
    setOldUserData(setupUserState(customerReducer))
    setImageUrls([
      { url: customerReducer?.customer?.idDocFrontUrl ?? '', id: 'dniFront' },
      { url: customerReducer?.customer?.idDocBackUrl ?? '', id: 'dniBack' },
    ])
  }, [customerReducer.customer])

  useEffect(() => {
    setRotateDegDocuments({
      ...rotateDegDocuments,
      dniFront: customerReducer.customer?.documentsRotation?.dniFront ?? 270,
      dniBack: customerReducer.customer?.documentsRotation?.dniBack ?? 270,
    })
  }, [customerReducer.customer?.documentsRotation])

  const handleDataChange = (id, value) => {
    setUserData({ ...userData, [id]: value })
  }

  const handleConfirmValidate = () => {
    validateForm()
    if (isFormValid()) {
      handleUpdateForAdmin()
      canOperate(userData.id, 'pendingValidate')
      onClose()
    }
  }

  const getUpdatedData = () => {
    return {
      mobilePhone: userData?.mobilePhone,
      idDocCode: userData?.idDocCode,
      firstName: userData.firstName,
      lastName: userData.lastName,
      birthday: userData.birthday,
      gender: userData.gender,
      country: userData?.country?.twoLetterISORegionName ?? 'ES',
      city: userData?.city,
      address: userData?.address,
      postalCode: userData?.postalCode,
      idDocType: userData?.idDocType,
      secondSurname: userData?.secondSurname,
    }
  }

  const handleConfirmDontValidate = (reason) => {
    cannotOperate(
      {
        id: customerData.id,
        reasons: [reason],
      },
      'pendingValidate',
    )
    onClose()
  }

  const validateForm = () => {
    let newErrors = errors

    newErrors.firstName = validations.required(userData?.firstName)
    newErrors.lastName = validations.required(userData?.lastName)
    newErrors.birthday = validations.required(userData?.birthday)
    newErrors.postalCode = validations.required(userData?.postalCode)
    newErrors.gender = validations.required(userData?.gender)
    newErrors.country = validations.required(userData?.country)
    newErrors.city = validations.required(userData?.city ?? '')
    newErrors.address = validations.required(userData?.address)
    newErrors.gender = validations.required(userData?.gender)

    if (userData?.docType === 'Pasaporte') {
      newErrors.nif = { result: true, message: '' }
    } else {
      newErrors.nif = validations.validateID(userData?.idDocCode)
    }

    if (userData?.mobilePhone.length > 0 && userData?.mobilePhone[0] !== '+') {
      setUserData({ ...userData, mobilePhone: '+' + userData?.mobilePhone })
      newErrors.phone = validations.validatePhone('+' + userData?.mobilePhone)
    } else newErrors.phone = validations.validatePhone(userData?.mobilePhone)
    newErrors.docType = validations.required(userData?.idDocType)

    setErrors({ ...newErrors })
  }

  const isFormValid = () => {
    let valid = true
    let properties = Object.getOwnPropertyNames(errors)
    properties.forEach((prop) => {
      if (!errors[prop].result) {
        valid = false
      }
    })
    return valid
  }

  const handleUnsavedChanges = () => {
    return JSON.stringify(oldUserData) === JSON.stringify(userData)
  }

  const handleClose = () => {
    handleUnsavedChanges() ? onClose() : setIsUnsavedChangesDialogOpen(true)
    window.location.href = '/customers'
  }

  const handleUpdateForAdmin = () => {
    const { email } = customerReducer?.customer
    const { dniFront, dniBack } = rotateDegDocuments
    update(email, {
      ...getUpdatedData(),
      documentsRotation: { dniBack, dniFront },
    })
    setOldUserData({ ...userData })
  }

  const sanitizeDate = (date) => (moment(date).isValid() ? date : null)

  const handleRefreshData = () => getCustomer(customerId)

  const handleSaveChanges = () => {
    validateForm()
    if (isFormValid()) {
      handleUpdateForAdmin()
      onClose()
    }
  }

  return open ? (
    <>
      <Container disableGutters maxWidth="xl" className={styles.modal}>
        <Container
          disableGutters
          maxWidth="xl"
          className={styles.contentWrapper}
        >
          <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
            <Grid container justify="space-between" style={{ paddingLeft: 50 }}>
              <Grid item className={styles.headerLeftSection}>
                <IconButton style={{ marginRight: 10 }} onClick={handleClose}>
                  <Close />
                </IconButton>
                <Typography className={styles.title}>
                  {' '}
                  {t('customers.dialogs.validateDocuments.dialogTitle')}{' '}
                </Typography>
              </Grid>
              <Grid alignItems="center" className={styles.headerSection}>
                <Button
                  color="inherit"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                    fontSize: '0.87rem',
                  }}
                  onClick={handleRefreshData}
                >
                  {t('customers.dialogs.validateDocuments.refresh')}
                </Button>
                <Button
                  color="inherit"
                  onClick={() => setInvalidateModalOpened(true)}
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                    fontSize: '0.87rem',
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  {t('customers.dialogs.validateDocuments.dontValidateBtn')}
                </Button>
                <Button
                  color="inherit"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    color: 'white',
                    fontSize: '0.87rem',
                    marginRight: 10,
                  }}
                  onClick={handleConfirmValidate}
                >
                  {t('customers.dialogs.validateDocuments.validateBtn')}
                </Button>
                <Button
                  color="inherit"
                  style={{
                    height: 'fit-content',
                    backgroundColor: '#e41622',
                    fontSize: '0.87rem',
                    color: 'white',
                  }}
                  onClick={handleSaveChanges}
                >
                  {t('customers.dialogs.validateDocuments.edit')}
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ height: '80vh', padding: 8 }}>
              <Grid xs item style={{ height: '100%' }}>
                <DocumentsCarousel
                  documents={imageUrls}
                  documentsRotation={rotateDegDocuments}
                  setDocumentsRotation={setRotateDegDocuments}
                />
              </Grid>
              <Grid
                container
                item
                xs
                spacing={1}
                style={{ margin: 0, height: '90%' }}
                direction="row"
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    id="idDocType"
                    value={userData?.idDocType ?? ''}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.docType',
                    )}
                    onChange={(e) => {
                      handleDataChange('idDocType', e.target.value)
                      errors.docType.message = ''
                      setErrors(errors)
                    }}
                  >
                    {customerReducer.docTypes.map((document) => (
                      <MenuItem
                        key={document}
                        id={'idDocType'}
                        value={document}
                      >
                        {document}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="idDocCode"
                    fullWidth
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t('customers.dialogs.validateDocuments.labels.nif')}
                    value={userData?.idDocCode ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.nif.message = ''
                      setErrors(errors)
                    }}
                    error={errors.nif.message.length === 0 ? false : true}
                    helperText={errors.nif.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    fullWidth
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t('customers.dialogs.validateDocuments.labels.name')}
                    value={userData?.firstName ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.firstName.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.firstName.message}
                    error={errors.firstName.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    fullWidth
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.lastName',
                    )}
                    value={userData?.lastName ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.lastName.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.lastName.message}
                    error={errors.lastName.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="secondSurname"
                    fullWidth
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.secondLastName',
                    )}
                    value={userData?.secondSurname ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="birthday"
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.birthDate',
                    )}
                    value={
                      (userData?.birthday &&
                        moment(userData.birthday).format('YYYY-MM-DD')) ||
                      ''
                    }
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.birthday.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.birthday.message}
                    error={errors.birthday.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="gender"
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    value={userData?.gender ?? ''}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.genre',
                    )}
                    onChange={(e) => handleDataChange('gender', e.target.value)}
                  >
                    <MenuItem key={'male'} id={'male'} value={'male'}>
                      {t('customers.dialogs.validateDocuments.labels.male')}
                    </MenuItem>
                    <MenuItem key={'female'} id={'female'} value={'female'}>
                      {t('customers.dialogs.validateDocuments.labels.female')}
                    </MenuItem>
                    <MenuItem key={'other'} id={'other'} value={'other'}>
                      {t('customers.dialogs.validateDocuments.labels.other')}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    inputProps={{ readOnly: true }}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.email',
                    )}
                    value={userData?.email ?? ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t(
                      'customers.dialogs.validateDocuments.labels.contactPhone',
                    )}
                    id="mobilePhone"
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    value={userData?.mobilePhone ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.phone.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.phone.message}
                    error={errors.phone.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="address"
                    fullWidth
                    label={t(
                      'customers.dialogs.validateDocuments.labels.address',
                    )}
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    value={userData?.address ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.address.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.address.message}
                    error={errors.address.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="city"
                    fullWidth
                    label={t('customers.dialogs.validateDocuments.labels.city')}
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    value={userData?.city ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.city.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.city.message}
                    error={errors.city.message.length === 0 ? false : true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="postalCode"
                    fullWidth
                    label={t(
                      'customers.dialogs.validateDocuments.labels.postalCode',
                    )}
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    value={userData?.postalCode ?? ''}
                    onChange={(e) => {
                      handleDataChange(e.target.id, e.target.value)
                      errors.postalCode.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.postalCode.message}
                    error={
                      errors.postalCode.message.length === 0 ? false : true
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true, className: styles.label }}
                    InputProps={{ className: styles.input }}
                    label={t(
                      'customers.dialogs.validateDocuments.labels.country',
                    )}
                    value={userData?.country ?? ''}
                    onChange={(e) => {
                      handleDataChange('country', e.target.value)
                      errors.country.message = ''
                      setErrors(errors)
                    }}
                    helperText={errors.country.message}
                    error={errors.country.message.length === 0 ? false : true}
                  >
                    {customerReducer.countries.map((country) => (
                      <MenuItem
                        key={country.code}
                        id={'country'}
                        value={country}
                      >
                        {country.displayName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Container>
      <ModalConfirm
        isOpen={invalidateModalOpened}
        onClose={() => setInvalidateModalOpened(false)}
        onConfirm={handleConfirmDontValidate}
        title={t('customers.dialogs.validateDocuments.dialogs.confirm.title')}
        inputAvaiable
        disabledCondition={DISABLED_CONSTANTS.NON_EMPTY_INPUT}
        label={t('customers.dialogs.validateDocuments.dialogs.confirm.label')}
      />
      <ModalConfirm
        isOpen={isUnsavedChangesDialogOpen}
        onClose={() => onClose()}
        title={t(
          'customers.dialogs.validateDocuments.dialogs.unhandledChanges.title',
        )}
        body={t(
          'customers.dialogs.validateDocuments.dialogs.unhandledChanges.body',
        )}
        onConfirm={() => {
          setIsUnsavedChangesDialogOpen(false)
          handleSaveChanges()
        }}
        cancelOption
        closeOnConfirm={false}
        onCancel={() => setIsUnsavedChangesDialogOpen(false)}
      />
    </>
  ) : null
}

function mapState(state) {
  const { customerReducer } = state
  return { customerReducer }
}

const actionCreators = {
  getCustomer: customerActions.getCustomerById,
  cannotOperate: customerActions.cannotOperate,
  canOperate: customerActions.canOperate,
  update: customerActions.update,
}

export default connect(mapState, actionCreators)(CustomerDocumentsValidation)
