const cleanBaseUrl = (baseUrl = true) => {
  const protocol = window.location.protocol
  const hostSplit = window.location.host.split('-')

  if (hostSplit[0] === 'localhost:3000') {
    return baseUrl
      ? 'https://dev-batterystation.connectivity.silence.eco'
      : 'https://pre-fleet.connectivity.silence.eco'
  } else {
    const urlToBackend = baseUrl
      ? 'batterystation.connectivity.silence.eco'
      : 'fleet.connectivity.silence.eco'

    return hostSplit.length === 1
      ? `${protocol}//${urlToBackend}`
      : `${protocol}//${hostSplit[0]}-${urlToBackend}`
  }
}

const baseURL = cleanBaseUrl()
const fleetGatewayURL = cleanBaseUrl(false)

const stringConstants = {
  URL_React: window.location + '/home',
  TIME_REFRESH_MAPS: 30000,
  URL_ApiGateway: `${baseURL}/api`,
  URL_FleetApiGateway: `${fleetGatewayURL}/api`,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
}

export default stringConstants
